/** @format */



import * as React from "react";

import Table from "@mui/material/Table";

import TableBody from "@mui/material/TableBody";

import TableCell from "@mui/material/TableCell";

import TableContainer from "@mui/material/TableContainer";

import TableHead from "@mui/material/TableHead";

import TableRow from "@mui/material/TableRow";

import { Link, Tooltip } from "@mui/material";

import FileDownloadIcon from '@mui/icons-material/FileDownload';

import { get, uniqWith, isEqual } from 'lodash';

import download from "../../../assets/download.png";

import moment from 'moment';

import { v4 as uuidv4 } from 'uuid';

import { useState } from 'react';

import FileViewer from './FileViewer';

function TreatmentHistoryAndMessages({ treatments, comments, downloadDocument, downloadDocumentZip, patient }) {

  const [modalOpen, setModalOpen] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [fileType, setFileType] = useState([]);

  const handleOpenModal = (files, fileType) => {
    setSelectedFiles(files);
    setModalOpen(true);
    setFileType(fileType);
  };

  const handleCloseModal = () => {
    setSelectedFiles([]);
    setModalOpen(false);
    setFileType("");
    console.log("closing modal");
  };

  const getFileLink = (treatment1, fileType, isComment) => {

    if (isComment) {
      const treatment = comments.filter(d => d.comment === treatment1.comment &&

        d.description === fileType);
      return treatment.length == 0 ?

        (<TableCell align="left" sx={{ borderBottom: " 2px solid black" }}>-</TableCell>)

        : treatment.length == 1 ? (

          <TableCell align="left" sx={{ borderBottom: " 2px solid black" }}>

            <Link sx={{ cursor: "pointer" }}

              onClick={() => downloadDocument(treatment1.documentId, treatment1.fileName)}>

              <Tooltip title={treatment1.fileName}>

                <img src={download} alt="download" />

              </Tooltip>

            </Link>

          </TableCell>

        ) : (

          <TableCell align="left" sx={{ borderBottom: " 2px solid black" }}>

            <Link sx={{ cursor: "pointer" }}

              onClick={() => handleOpenModal(treatment, fileType)} // Open the modal for multiple files

            >

              <Tooltip title={'View Attachments'}>



                <img src={download} alt="download" />

              </Tooltip>



            </Link>

          </TableCell>

        );
    }
    else {
      const treatment = treatments.filter(d => d.treatment === treatment1.treatment &&

        d.description === fileType);

      //console.log("treatments before filter", treatments);

      //console.log("treatments after filter", treatment);

      return treatment.length == 0 ?

        (<TableCell align="left" sx={{ borderBottom: " 2px solid black" }}>-</TableCell>)

        : treatment.length == 1 ? (

          <TableCell align="left" sx={{ borderBottom: " 2px solid black" }}>

            <Link sx={{ cursor: "pointer" }}

              onClick={() => downloadDocument(treatment[0].documentId, treatment[0].fileName)}>

              <Tooltip title={treatment[0].fileName}>

                <img src={download} alt="download" />

              </Tooltip>

            </Link>

          </TableCell>

        ) : (

          <TableCell align="left" sx={{ borderBottom: " 2px solid black" }}>

            <Link sx={{ cursor: "pointer" }}

              onClick={() => handleOpenModal(treatment, fileType)} // Open the modal for multiple files

            >

              <Tooltip title={'View Attachments'}>



                <img src={download} alt="download" />

              </Tooltip>



            </Link>

          </TableCell>

        );
    }
  }

  // Combine treatments with the same name
  const aggregatedTreatments = {};
  treatments.forEach(item => {
    if (item.treatment) {
      if (!aggregatedTreatments[item.treatment]) {
        aggregatedTreatments[item.treatment] = { ...item, documentIds: [item.documentId] };
      } else {
        // Combine information from multiple treatments with the same name
        aggregatedTreatments[item.treatment].documentIds.push(item.documentId);
        // Add additional logic based on your requirements
      }
    }
  });

  // Convert the aggregated treatments object into an array
  const fixedTreatments = Object.values(aggregatedTreatments);

  // Combine treatments and comments into a single array
  const combinedData = [...fixedTreatments, ...comments];

  // Sort the combined array by date in descending order
  combinedData.sort((a, b) => {
    const dateA = new Date(a.date);
    const dateB = new Date(b.date);

    // Custom logic to prioritize "ORIGINAL REFERRAL SUBMITTED"
    if (a.treatment === "Original referral submitted") return 1;
    if (b.treatment === "Original referral submitted") return -1;

    return dateB - dateA;
  });

  return (
    <>
      <TableContainer sx={{ height: '100%', overflowY: 'auto' }}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead sx={{ position: 'sticky', top: '0', zIndex: '3', background: 'white' }}>
            <TableRow>
              <TableCell sx={{ borderBottom: " 2px solid black" }}>Date</TableCell>

              <TableCell sx={{ borderBottom: " 2px solid black", columnWidth: "550px" }}>Treatment</TableCell>

              <TableCell sx={{ borderBottom: " 2px solid black" }}>Letter</TableCell>

              <TableCell sx={{ borderBottom: " 2px solid black" }}>X-Ray</TableCell>

              <TableCell sx={{ borderBottom: " 2px solid black" }}>Image</TableCell>

              <TableCell sx={{ borderBottom: " 2px solid black" }}>Perio-Chart</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {combinedData.map((item) => {
              const temporaryKey = `temp_${uuidv4()}`;
              return (
                <TableRow
                  key={temporaryKey} // Assuming each item has a unique identifier (adjust accordingly)
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 }, color: "#121828",

                    backgroundColor: item?.role === 1 ? "#98d6ec" : "",

                    padding: "16px",
                  }}
                >
                  <TableCell component="th" scope="row" sx={{ borderBottom: " 2px solid black", columnWidth: "50%" }}>
                    {moment.utc(item.date).format("DD-MMM-YYYY")}
                  </TableCell>

                  {item.treatment ? (
                    // It's a treatment
                    <>
                      <TableCell align="left" sx={{ borderBottom: " 2px solid black" }}>{item.treatment}</TableCell>
                      {getFileLink(item, "Letter", false)}
                      {getFileLink(item, "X-Ray", false)}
                      {getFileLink(item, "Image", false)}
                      {getFileLink(item, "Perio-Chart", false)}
                    </>
                  ) : (
                    // It's a comment/message
                    <>
                      <TableCell align="left" sx={{ borderBottom: " 2px solid black" }}>

                        {item.name} - <br></br> {item.comment}

                      </TableCell>
                      {getFileLink(item, "Letter", true)}
                      {getFileLink(item, "X-Ray", true)}
                      {getFileLink(item, "Image", true)}
                      {getFileLink(item, "Perio-Chart", true)}
                    </>
                  )}

                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {modalOpen && (
        <FileViewer
          fileType={fileType}
          files={selectedFiles}
          downloadDocument={downloadDocument}
          onClose={handleCloseModal}
          patientId={patient.id}
        />
      )}
    </>
  );
}

export default TreatmentHistoryAndMessages;
